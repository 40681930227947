<script setup lang="ts">
import { onMounted } from 'vue';
import { useApplicationInsights } from '~/store/applicationInsights';
const appInsights = useApplicationInsights();
const runtimeConfig = useRuntimeConfig();
const route = useRoute();
const headers = useRequestHeaders();

if (route.fullPath.toLowerCase().startsWith('/api/'))
{
  throw createError({ statusCode: 404, statusMessage: 'API not found' });
}
else if (route.fullPath.toLowerCase().startsWith('/productpage/stockstatus'))
{
  let logMessage = 'IP: ' + headers['x-forwarded-for'] + ',tried to access depricated product stockstatus api: ' + route.fullPath;
  //console.log(logMessage);
  appInsights.trackTrace(logMessage);
  throw createError({ statusCode: 404, statusMessage: 'API not found' });
}

onMounted(()=> {
  // const favouritesStore = useFavouritesStore();
  const appInsights = useApplicationInsights();
  appInsights.initialize();
  // favouritesStore.fetchFavourites();
});

const gtmId = runtimeConfig.public.gtmContainerId;

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk}` : 'Swedish Match';
  },
  script: [
    {
      src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
      type: 'text/javascript',
      'data-document-language': 'true',
      'data-domain-script': runtimeConfig.public.onetrustDataDomainScript,
    },
    {
      innerHTML: `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}');
    `,
    },
  ],
});
</script>

<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.2s;
  transition-delay: 0ms;
  transition-timing-function: ease-in-out;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
}
</style>

